export const companyBrands = [
    'Chery',
    'Citroen',
    'Fiat',
    'Hyundai',
    'Jeep',
    'Kia',
    'Nissan',
    'Renault',
    'Volkswagen'
]

