import { Injectable } from '@angular/core';
import { Message } from '../interface/message';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';
import { Observable, first, firstValueFrom, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private _isFromMatrix = false;

  private messages = new Map<string, Map<string, Message>>();
  private timestamps = new Map<string, number>();


  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) { }

  getAll(): Observable<Map<string, Message>>{
    let timestamp = this.getTimestamp();
    const url = `${environment.mkgoURL}/api/v1/messages/${new Date(timestamp).toISOString()}`;
    return this.dataService.httpOptions(this._isFromMatrix)
      .pipe(
        switchMap(options => this.http.get<{ messages: Message[] }>(url, options)),
        map(resp => {
          const localCompanyMessages: Map<string, Message> = this.messages?.get(this.dataService.company?.id) || new Map();
          for (const newMessage of resp.messages) {
            timestamp = Math.max(timestamp, new Date(newMessage.createdAt || 0).getTime());
            localCompanyMessages.set(newMessage.id, newMessage);
          }
          this.messages.set(this.dataService.company.id, localCompanyMessages);
          this.timestamps.set(this.dataService.company.id, timestamp);
          return localCompanyMessages;
        })
      );
  }

  async countUnread(cnpj: string) {
    const url = `${environment.mkgoURL}/api/v1/messages/${new Date(0).toISOString()}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    header.headers = header.headers.set('Company-Cnpj', cnpj);
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    return response.messages;
  }

  getTimestamp() {
    return this.timestamps.get(this.dataService.company.id) || 0;
  }

}

