export const REGIM_ESPEC_TRIBUT_PREST = [
    { code: "0", description: "Não possui" },
    { code: "1", description: "Microempresa municipal" },
    { code: "2", description: "Estimativa" },
    { code: "3", description: "Sociedade de profissionais" },
    { code: "4", description: "Cooperativa" },
    { code: "5", description: "Microempresário Individual (MEI)" },
    { code: "6", description: "Microempresário e Empresa de Pequeno Porte (ME EPP)" },
    { code: "7", description: "Optante pelo Simples Nacional" },
    { code: "8", description: "Tributação Normal" },
    { code: "9", description: "Autônomo" },
    { code: "10", description: "Variável" },
    { code: "11", description: "Lucro Real" },
    { code: "12", description: "Lucro Presumido" },
    { code: "13", description: "Sociedade de Profissionais Pessoa Jurídica" },
    { code: "14", description: "Não" },
    { code: "15", description: "Notas Totalizadoras" },
    { code: "16", description: "Inscrito no PRODEVAL" }
];

/**
 * Used by E&L pattern
 */
export const REGIM_ESPEC_TRIBUT_PREST_EL = [
  { code: "1", prefecture: "1", description: "Microempresa municipal" },
  { code: "2", prefecture: "2", description: "Estimativa" },
  { code: "3", prefecture: "3", description: "Sociedade de profissionais" },
  { code: "4", prefecture: "4", description: "Cooperativa" },
  { code: "5", prefecture: "5", description: "Microempresário Individual (MEI)" },
  { code: "6", prefecture: "6", description: "Microempresário e Empresa de Pequeno Porte (ME EPP)" },
  { code: "9", prefecture: "7", description: "Autônomo" },
  { code: "19", prefecture: "8", description: "Média Empresa"},
  { code: "20", prefecture: "9", description: "Grande Empresa"},
  { code: "18", prefecture: "10", description: "Empresa Individual de responsabilidade limitada EIRELI"}
];
